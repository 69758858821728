import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export default function JboButtonGroup({ text, buttons }) {
  return (
    <>
      <ButtonGroup aria-label="button group">
      {buttons.map((button, i) => (
        <a href={button.link}>
          <Button key={i} name={button.text}>
            {button.text}
          </Button>
        </a>
      ))}
      </ButtonGroup>
    </>
  );
};