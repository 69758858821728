import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress, {
  circularProgressClasses
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function JboCircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          color: theme.palette.grey[200],
          ...theme.applyStyles('dark', {
            color: theme.palette.grey[800],
          }),
        })}
        {...props}
        value={100}
      />
      <CircularProgress 
        variant="determinate"
        sx={(theme) => ({
          color: '#1a90ff',
          animation: `nfLoaderSpin infinite 700ms linear`,
          position: 'absolute',
          left: 0,
          animationDuration: '550ms',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          ...theme.applyStyles('dark', {
            color: '#308fe8',
          }),
          "@keyframes nfLoaderSpin": {
            from: {
              transform: "rotate(0deg)"
            },
            to: {
              transform: "rotate(360deg)"
            }
          }
        })}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary', fontSize: "2em" }}
        >{`${props.text || ""}`}</Typography>
      </Box>
    </Box>
  );
}

JboCircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
