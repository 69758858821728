import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import getActionScreenTheme from './theme/getActionScreenTheme';
import TemplateFrame from './TemplateFrame';
import ActionScreen from './ActionScreen';
import StartScreen from './StartScreen';
import ToggleColorMode from './ToggleColorMode';
import { socket } from './socket';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const ActionScreenContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: 20,
  backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  backgroundRepeat: 'no-repeat',
  ...theme.applyStyles('dark', {
    backgroundImage:
      'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
  }),
}));

export default function App() {

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [mode, setMode] = React.useState('light');

  const [progressValue, setProgressValue] = React.useState(5);
  const [progressText, setProgressText] = React.useState("");
  const [buttonGroup, setButtonGroup] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [screenType, setSceenType] = React.useState(null);

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {

    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onProgressValueEvent(value) {
      setProgressValue(previousValue => value);
    }

    function onProgressTextEvent(value) {
      setProgressText(previousValue => value);
    }

    function onButtonGroupEvent(value) {
      setButtonGroup(previousValue => value);
    }

    function onTitleEvent(value) {
      setTitle(previousValue => value);
    }

    function onTextEvent(value) {
      setText(previousValue => value);
    }

    function onScreenTypeEvent(value) {
      setSceenType(previousValue => value);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('actionScreenProgressText', onProgressTextEvent);
    socket.on('actionScreenProgressValue', onProgressValueEvent);
    socket.on('actionScreenButtonGroup', onButtonGroupEvent);
    socket.on('actionScreenTitle', onTitleEvent);
    socket.on('actionScreenText', onTextEvent);
    socket.on('actionScreenType', onScreenTypeEvent);


    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('actionScreenProgressText', onProgressTextEvent);
      socket.off('actionScreenProgressValue', onProgressValueEvent);
      socket.off('actionScreenButtonGroup', onButtonGroupEvent);
      socket.off('actionScreenTitle', onTitleEvent);
      socket.off('actionScreenText', onTextEvent);
      socket.off('actionScreenType', onScreenTypeEvent);
    };

  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode); // Save the selected mode to localStorage
  };

  const ActionScreenTheme = createTheme(getActionScreenTheme(mode));

  return (
    <TemplateFrame mode={mode}>
      <ThemeProvider theme={ActionScreenTheme}>
        <CssBaseline enableColorScheme />
        <ActionScreenContainer direction="column" justifyContent="space-between">
          <Card variant="outlined">
            {isConnected
              ? <ActionScreen title={title} text={text} screenType={screenType} progressText={progressText} progressValue={progressValue} buttonGroup={buttonGroup}/>
              : <StartScreen />
            }
            <Divider/>
            <CardActions 
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // 👇 Edit padding to further adjust position
                p: 0,
              }}>
              <Box>Jugendblasorchester Meitingen e.V.</Box>
              <Box sx={{ gap: 4 }}>
                <ToggleColorMode
                  data-screenshot="toggle-mode"
                  mode={mode}
                  toggleColorMode={toggleColorMode}
                />
              </Box>
            </CardActions>
          </Card>
        </ActionScreenContainer>
      </ThemeProvider>
    </TemplateFrame>
  );
}