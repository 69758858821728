import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import JboCircularProgressWithLabel from './JboCircularProgressWithLabel';
import JboButtonGroup from './JboButtonGroup';

export default function ActionScreen(props) {

  return (
    <Stack spacing={5}>
        <Box>

            <Typography component="h1" variant="h4" sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center' }} >
            {props.title}
            </Typography>

        </Box> 

        {props.text ? (
          <Box>
              <Typography sx={{ textAlign: 'center' }}>
                  {props.text}
              </Typography>
          </Box>
        ): null}

        <Box>

            <Box sx={{ alignItems:"center", justifyContent:"center", display:"flex", flexDirection: 'column', gap: 2 }}>
            {(() => {
                switch (props.screenType) {
                case 'circularProgress':
                    return <JboCircularProgressWithLabel value={props.progressValue} text={props.progressText} thickness="3" size="200px" />
                case 'buttonGroup':
                    return <JboButtonGroup buttons={props.buttonGroup || []}/>
                default:
                    return null
                }
            })()}
            </Box>

        </Box>

        <Box>

        </Box>
    </Stack>
  );
}

ActionScreen.propTypes = {
  /**
   * Titel der Anzeige
   */
  title: PropTypes.string,
  /**
   * Text der Anzeige
   */
  text: PropTypes.string,
  /**
   * Interface fuer die Anzeige
   */
  screenType: PropTypes.string,
  /**
   * Text fuer die Ladeanzeige
   */
  progressText: PropTypes.string,
  /**
   * Angabe ueber den Fortschritt des Ladevorgangs
   * @default 0
   */
  progressValue: PropTypes.number,
  /**
   * Array mit Buttons
   */
  buttonGroup: PropTypes.array
};
