import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';
import { socket } from './socket';

export default function StartScreen() {

  function connect() {
    socket.connect();
  }

  return (
    <Stack spacing={5}>
      <Box>
          <Typography component="h1" variant="h4" sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center' }} >
            Aktion starten
          </Typography>
      </Box> 
      <Box sx={{ alignItems:"center", justifyContent:"center", display:"flex", flexDirection: 'column', gap: 2 }}>
          <Button variant="outlined" size="large" onClick={ connect } endIcon={<NavigateNextIcon />}>Los</Button>
      </Box>
      <Box>
        <Typography sx={{ textAlign: 'center' }}>
          Die Aktion kann über "Los" gestartet werden. Sollte keine Verbindung herstellt werden können oder abgebrochen sein, kann der Vorgang nochmal neugestartet werden.
        </Typography>
      </Box>
    </Stack>
  );
}