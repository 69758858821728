import { io } from 'socket.io-client';

const toBoolean = (dataStr) => {
    return !!(dataStr?.toLowerCase?.() === 'true' || dataStr === true);
};

// "undefined" means the URL will be computed from the `window.location` object
const URL = window.location.pathname;

const searchParams = new URLSearchParams(window.location.search);

let autoConnect = true;
if(searchParams.has('autoconnect')) {
    autoConnect = toBoolean(searchParams.get('autoconnect'));
}

export const socket = io(URL, { autoConnect: autoConnect });